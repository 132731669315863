import React from "react"; // eslint-disable-line
import loadable from "react-loadable";
import LoadingIndicator from "./RouteLoading";

const options = {
  loading: LoadingIndicator
};

export const ViewEpisodeRoute = loadable({
  loader: () => import("./ViewEpisode/ViewEpisodeRoute"),
  ...options
});

export const Login = loadable({
  loader: () => import("./Auth/Login"),
  ...options
});

export const PublisherList = loadable({
  loader: () => import("./Publishers"),
  ...options
});

export const Stripe = loadable({
  loader: () => import("./Stripe"),
  ...options
});

export const AsyncAPI = loadable({
  loader: () => import("./Introspection"),
  ...options
});

export const Coach = loadable({
  loader: () => import("./Coach"),
  ...options
});

export const RoutesAdminAsync = loadable({
  loader: () => import("./RoutesAdmin"),
  ...options
});

export const Invitation = loadable({
  loader: () => import("./Invitations"),
  ...options
});

export const PublisherInvitation = loadable({
  loader: () => import("./Invitations/PublisherInvitation"),
  ...options
});

export const Group = loadable({
  loader: () => import("./Group"),
  ...options
});

export const Settings = loadable({
  loader: () => import("./Settings"),
  ...options
});

export const Publisher = loadable({
  loader: () => import("./Publisher"),
  ...options
});

export const Course = loadable({
  loader: () => import("./CoursePurchase"),
  ...options
});

export const Dashboard = loadable({
  loader: () => import("./Dashboard"),
  ...options
});

export const About = loadable({
  loader: () => import("./Branding/About"),
  ...options
});

export const Services = loadable({
  loader: () => import("./Branding/Services"),
  ...options
});
