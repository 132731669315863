import * as React from "react";
import Box from "../Kit/Box";
import Flex from "../Kit/Flex";
import Avatar from "../Kit/Avatar";
import Text, { Link } from "../Kit/Text";
import {isAfter} from "date-fns";
import Dropdown from "../Kit/Dropdown";
import Editor from "../Kit/RichText";
import { HeartIcon } from "../Kit/Icon";
import { Tooltip, Button } from "../Kit";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import IconButton from "../Kit/IconButton";
import notify from "../Kit/Notification";
import Raven from "raven-js";
import pluralize from "pluralize";
import Message from "../Input/Message";
import { takeRight } from "lodash";
import {
  LIKE_COMMENT,
  LIKE_ANNOTATION,
  UNLIKE_ANNOTATION,
  UNLIKE_COMMENT,
  CREATE_COMMENT,
  UPDATE_ANNOTATION,
  UPDATE_COMMENT,
  AnnotationFragment,
  QuestionFragment
} from "./episode-queries";
import styled from "styled-components/macro";
import scrollIntoView from "scroll-into-view-if-needed";
import moment from "moment";
import debug from "debug";
import { isNumber } from "lodash";
import { emitter } from "./state";
import RenderResource from "../Input/RenderResource";
import Input from "../Input";
import RenderFile from "../Input/RenderFile";
import { QuestionWithoutCompletionFragment } from "../API/fragments/QuestionFragment";

const log = debug("app:Annotation");

const TextWithBullet = styled(Text)`
  :before {
    content: "\\00b7";
    padding-right: 3px;
    padding-left: 3px;
  }
`;

const ResourceBox = styled.div`
  font-size: 0;
`;

export function formatTimestamp(seconds) {
  return [parseInt((seconds / 60) % 60, 10), parseInt(seconds % 60, 10)]
    .join(":")
    .replace(/\b(\d)\b/g, "0$1");
}

const AnnotationStyled = Flex;

export const BorderedBox = styled(Box)`
  font-weight: 400;
  max-width: 100%;
  display: inline-block;
  width: auto;
  line-height: 1.3;
  border-radius: 16px;
  padding: 8px 16px;
`;

const ContainerBox = styled(Box)`
  & .Annotation-show-options {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease;
  }

  &:hover .Annotation-show-options {
    opacity: 1;
    pointer-events: auto;
  }

  & .Annotation-show-options[aria-expanded="true"] {
    opacity: 1;
    pointer-events: auto;
  }
`;

// type Props = {
//   showAllComments?: boolean,
//   step: Object,
//   section: Object,
//   visible: boolean,
//   annotation: Object,
//   postedBy?: Object,
//   showDate?: boolean,
//   isQuestion: boolean,
//   showCommentId?: number,
//   onReply?: Function,
//   commentable: string,
//   viewer: Object,
//   icon?: React.Node,
//   size?: string,
//   color?: string,
//   courseId: number,
//   createComment: Function,
//   unlikeComment: Function,
//   unlikeAnnotation: Function,
//   createComment: Function,
//   showTimestamp: boolean,
//   enableLike: boolean,
//   role: string,
//   truncate?: boolean,
//   theme?: string,
//   style?: Object,
//   groupId: number,
//   enrollmentId: number,
//   mode: string
// };

// type State = {
//   replying: boolean,
//   content: any,
//   showAll: boolean,
//   editing: boolean,
//   editContent: ?any,
//   mounted: Date,
//   showCommentList: Set
// };

class Annotation extends React.Component {
  // replyBox: any;

  constructor(props) {
    super(props);

    const showComments = new Set();

    // ignore if we are rendering a comment
    if (props.annotation && props.annotation.comments) {
      if (props.showCommentId) {
        showComments.add(props.showCommentId);
      } else {
        const comments = props.annotation.comments;
        // show the last two by default
        takeRight(comments, 2).forEach(comment => {
          showComments.add(comment.id);
        });
      }
    }

    if (props.showAllComments && props.annotation.comments) {
      props.annotation.comments.forEach(comment => {
        showComments.add(comment.id);
      });
    }

    this.state = {
      mounted: new Date(),
      replying: false,
      content: Editor.createEmpty(),
      showAll: false,
      editing: false,
      editContent: null,
      showCommentList: showComments
    };
  }

  static defaultProps = {
    visible: true,
    size: "medium",
    enableLike: true,
    showDate: true,
    theme: "gray.3",
    color: "black",
    showTimestamp: true,
    commentable: "stepannotation",
    truncate: true
  };

  render() {
    const {
      enableLike,
      showDate,
      annotation,
      size,
      icon,
      truncate,
      color,
      viewer,
      showTimestamp,
      isQuestion,
      postedBy
    } = this.props;

    const { createdBy, comments } = this.props.annotation;
    const { replying, editing, showCommentList } = this.state;
    const user = createdBy || postedBy;
    const commentCount = comments && comments.length;
    let likes = annotation.ratingCount || 0;
    const isUser = viewer && user && viewer.id === user.id;

    const renderComments = commentList => {
      let skipped = [];

      return commentList.map(comment => {
        // render a comment if it was created after this annotation list was mounted.
        // used to ensure newly created comments are rendered
        if (
          isAfter(comment.createdAt, this.state.mounted) ||
          showCommentList.has(comment.id)
        ) {
          let showMoreButton =
            skipped.length > 0 ? (
              <Box mt={1} ml="45px" className="ShowMoreButton">
                <Button
                  size="small"
                  key={"show-more" + comment.id}
                  color="black"
                  style={{ fontWeight: 500, color: "#1f4160" }}
                  onClick={this.showComments.bind(this, [...skipped])}
                  appearance="link"
                >
                  Show {skipped.length} additional{" "}
                  {pluralize("comment", skipped.length)}
                </Button>
              </Box>
            ) : null;

          skipped = [];

          return (
            <React.Fragment key={comment.id}>
              {showMoreButton}
              <AnnotationWithFunctions
                {...this.props}
                size="small"
                key={comment.id}
                icon={null}
                enableLike={true}
                annotation={comment}
                onReply={this.reply}
                showDate
              />
            </React.Fragment>
          );
        } else {
          skipped.push(comment);
          return null;
        }
      });
    };

    const els = commentCount > 0 ? renderComments(comments) : null;

    return (
      <AnnotationStyled mb={1} mt={1} bg="transparent">
        {(user || icon) && (
          <Box alignSelf="flex-start" mt="2px">
            {icon ? (
              icon
            ) : (
              <Avatar
                size={size === "small" ? "tiny" : "small"}
                name={user.name}
                img={user.photo}
              />
            )}
          </Box>
        )}
        <Box
          id={this.props.annotation.__typename + annotation.id}
          ml={isQuestion ? 0 : 1}
          flex="1 1 auto"
          overflow="hidden"
        >
          <Box>
            {editing ? (
              this.renderInputBox({
                isComment: false,
                viewer,
                content: this.state.editContent,
                courseId: this.props.courseId,
                onChange: this.changeEdit,
                submit: this.submitEdit,
                file: annotation.image,
                resource: annotation.resource
              })
            ) : (
              <ContainerBox pr="32px">
                <BorderedBox
                  color="rgb(22, 23, 26)"
                  isQuestion={this.props.isQuestion}
                  style={this.props.style}
                  bg={this.props.theme}
                  position="relative"
                >
                  {isUser && (
                    <Dropdown
                      trigger={
                        <ShowMoreButton
                          className="Annotation-show-options"
                          label="Show options"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-more-vertical"
                          >
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="12" cy="5" r="1" />
                            <circle cx="12" cy="19" r="1" />
                          </svg>
                        </ShowMoreButton>
                      }
                    >
                      <Dropdown.ItemButton onClick={this.showEdit}>
                        Edit
                      </Dropdown.ItemButton>
                    </Dropdown>
                  )}

                  {user && (
                    <Text
                      mr={"3px"}
                      color={color}
                      fontWeight={600}
                      faded
                      compressed
                      fontSize={1}
                    >
                      {user.name}
                    </Text>
                  )}

                  <Text color={color} fontSize={1} compressed>
                    <AnnotationContent
                      truncate={truncate}
                      content={annotation.content}
                    />
                  </Text>

                  {likes > 0 && (
                    <Flex
                      alignItems="center"
                      bg="gray.3"
                      position="absolute"
                      bottom="-6px"
                      right="-10px"
                      p="3px"
                      borderRadius="8px"
                      ml={1}
                    >
                      <HeartIcon
                        stroke="#f36331"
                        style={{
                          fill: "#f36331",
                          width: "16px",
                          height: "16px",
                          marginRight: likes > 1 ? "4px" : 0
                        }}
                      />
                      {likes > 1 && (
                        <Text
                          fontWeight="bold"
                          color="green.20"
                          fontSize={0}
                          mr={"3px"}
                          ml={"0"}
                        >
                          {likes}
                        </Text>
                      )}
                    </Flex>
                  )}
                </BorderedBox>

                {annotation.image && (
                  <ResourceBox>
                    <Box
                      bg="gray.3"
                      display="inline-block"
                      overflow="hidden"
                      maxWidth="100%"
                      borderRadius="16px"
                      mt={1}
                    >
                      <RenderFile radius="16px" file={annotation.image} />
                    </Box>
                  </ResourceBox>
                )}

                {annotation.resource && (
                  <ResourceBox>
                    <Box
                      bg="gray.3"
                      display="inline-block"
                      overflow="hidden"
                      maxWidth="100%"
                      borderRadius="16px"
                      mt={1}
                    >
                      <RenderResource resource={annotation.resource} />
                    </Box>
                  </ResourceBox>
                )}
              </ContainerBox>
            )}
          </Box>

          {!editing && (
            <Flex
              pl={"16px"}
              alignItems="center"
              mt={"2px"}
              mb={0}
              position="relative"
            >
              {enableLike && (
                <Button
                  mr={1}
                  fontWeight="400"
                  appearance="link"
                  size="small"
                  style={{
                    color: annotation.likedByViewer ? "#f46232" : null
                  }}
                  onClick={this.like}
                >
                  Like
                </Button>
              )}

              <Button
                fontWeight="400"
                appearance="link"
                size="small"
                onClick={this.reply}
              >
                Reply
              </Button>

              {showDate && (
                <TextWithBullet fontSize={1} faded>
                  {moment(annotation.createdAt)
                    .local()
                    .fromNow()}
                </TextWithBullet>
              )}

              {showTimestamp && isNumber(annotation.beginsAt) && (
                <Text flex="1 1 auto" textAlign="right" ml="auto">
                  <Tooltip
                    placement="left"
                    arrow
                    title="Skip to this point in the video"
                  >
                    <Link
                      compressed
                      fontSize={1}
                      href="#"
                      onClick={this.skipTo}
                    >
                      {`${formatTimestamp(annotation.beginsAt)}`}
                    </Link>
                  </Tooltip>
                </Text>
              )}
            </Flex>
          )}

          {els}

          {replying &&
            this.renderInputBox({
              isComment: true,
              viewer,
              file: null,
              resource: null,
              content: this.state.content,
              courseId: this.props.courseId,
              onChange: this.onChange,
              submit: this.submit
            })}
        </Box>
      </AnnotationStyled>
    );
  }

  showComments = comments => {
    this.setState(state => {
      comments.forEach(comment => {
        state.showCommentList.add(comment.id);
      });

      return state;
    });
  };

  renderInputBox = ({
    isComment,
    viewer,
    content,
    file,
    resource,
    onChange,
    submit,
    courseId
  }) => {
    return (
      <Flex mt={1} align="flex-start">
        {isComment && (
          <Box mt={2} flex={"0 0 auto"}>
            <Avatar name={viewer.name} img={viewer.photo} size={"tiny"} />
          </Box>
        )}
        <Box pl={1} width={1} style={{ overflow: "hidden" }} flex="1 1 auto">
          <Box
            border="1px solid"
            borderColor="borderColor"
            borderRadius="16px"
            bg="gray.1"
            ref={el => (this.replyBox = el)}
            width={1}
          >
            <Input
              networkDisabled={false}
              state={content}
              enableSubmit
              file={file}
              resource={resource}
              rounded
              autoClear={false}
              placeholder="Write a comment..."
              autoFocus
              courseId={courseId}
              onChange={onChange}
              onSubmit={submit}
            />
          </Box>
        </Box>
      </Flex>
    );
  };

  showEdit = () => {
    this.setState({
      editing: true,
      editContent: Editor.deserialize(this.props.annotation.content).value
    });
  };

  skipTo = e => {
    e.preventDefault();
    emitter.emit("time-update", this.props.annotation.beginsAt);
  };

  changeEdit = content => {
    this.setState({ editContent: content });
  };

  submitEdit = (val, attachments = {}) => {
    this._content = this.state.editContent;
    let text = Editor.serialize(this.state.editContent);
    log("submit edit: %s", text);

    if (this.props.annotation.__typename === "Annotation") {
      this.updateAnnotation(this.props.annotation.id, text, attachments);
    } else {
      this.updateComment(this.props.annotation.id, text, attachments);
    }
  };

  updateComment = async (id, text, attachments = {}) => {
    const isCoach = this.props.role !== "STUDENT";

    try {
      await this.props.updateComment({
        variables: {
          id,
          input: {
            content: text,
            resourceId: attachments.resource ? attachments.resource.id : null,
            imageId: attachments.image ? attachments.image.id : null
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateAnnotationComment: {
            __typename: "Comment",
            id: Math.random(),
            createdBy: {
              __typename: "User",
              id: this.props.viewer.id,
              name: this.props.viewer.name,
              photo: this.props.viewer.photo
            },
            resource: attachments.resource,
            image: attachments.image
              ? {
                  id: attachments.image.id,
                  __typename: "Image",
                  url: attachments.image.url,
                  original_filename: attachments.image.original_filename
                }
              : null,
            content: text,
            createdAt: this.props.annotation.createdAt,
            enrollmentId: isCoach ? null : this.props.enrollmentId,
            commentable: this.props.commentable,
            commentableId: this.props.annotation.id,
            likedByViewer: this.props.annotation.likedByViewer,
            ratingCount: this.props.annotation.ratingCount
          }
        }
      });

      this.setState({
        editing: false,
        editContent: null
      });
    } catch (err) {
      this.setState({
        editing: true,
        editContent: this._content
      });
      console.error(err);
      Raven.captureException(err);
      notify.send({
        message:
          "An error occurred while updating your comment. Please try again.",
        type: "danger"
      });
    }
  };

  updateAnnotation = async (id, text, attachments = {}) => {
    try {
      await this.props.updateAnnotation({
        variables: {
          id,
          input: {
            content: text,
            resourceId: attachments.resource ? attachments.resource.id : null,
            imageId: attachments.image ? attachments.image.id : null
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateAnnotationComment: {
            ...this.props.annotation,
            resource: attachments.resource,
            image: attachments.image
              ? {
                  id: attachments.image.id,
                  __typename: "Image",
                  url: attachments.image.url,
                  original_filename: attachments.image.original_filename
                }
              : null,
            content: text
          }
        }
      });

      this.setState({
        editing: false,
        editContent: null
      });
    } catch (err) {
      this.setState({
        editing: true,
        editContent: this._content
      });

      console.error(err);
      Raven.captureException(err);
      notify.send({
        message:
          "An error occurred while updating your annotation. Please try again.",
        type: "danger"
      });
    }
  };

  submit = async (val, attachments = {}) => {
    let text = Editor.serialize(this.state.content);
    log("submit %s", text);

    this.setState({ replying: false });

    try {
      const isCoach = this.props.role !== "STUDENT";

      log("submitting as: %s", this.props.role);

      const res = await this.props.createComment({
        refetchQueries: ["Group"],
        update: (proxy, { data: { createAnnotationComment } }) => {
          if (this.props.commentable === "stepannotation") {
            log("create comment for annotation");

            try {
              const fragmentInfo = {
                id: "Annotation:" + this.props.annotation.id,
                fragment: AnnotationFragment,
                fragmentName: "Annotation"
              };

              const data = proxy.readFragment(fragmentInfo);

              proxy.writeFragment({
                ...fragmentInfo,
                data: {
                  ...data,
                  comments: [...data.comments, createAnnotationComment]
                }
              });
            } catch (err) {
              console.warn("Error writing step annotation comment");
            }

            // try {

            //    const fragmentInfo = {
            //     id: "Annotation:" + this.props.annotation.id,
            //     fragment: AnnotationFragment,
            //     fragmentName: "Annotation"
            //   };

            //   const data = proxy.readFragment(fragmentInfo);

            //   proxy.writeFragment({
            //     ...fragmentInfo,
            //     data: {
            //       ...data,
            //       comments: [...data.comments, createAnnotationComment]
            //     }
            //   });

            // } catch (err) {
            //   console.warn('Error writing step annotation comment anon')
            // }
          } else if (this.props.commentable === "stepquestion") {
            log("create a comment for a step question");
            const fragmentInfo = {
              id: "StepQuestion:" + this.props.annotation.id,
              fragment: QuestionFragment,
              fragmentName: "Question",
              variables: {
                groupId: this.props.groupId,
                enrollmentId: this.props.enrollmentId
              }
            };

            log("create comment for question: %O", fragmentInfo);

            try {
              const data = proxy.readFragment(fragmentInfo);
              proxy.writeFragment({
                ...fragmentInfo,
                data: {
                  ...data,
                  completed: true,
                  comments: [...data.comments, createAnnotationComment]
                }
              });
            } catch (err) {
              console.warn("error writing QuestionFragment");
            }

            try {
              const frag = {
                id: "StepQuestion:" + this.props.annotation.id,
                fragment: QuestionWithoutCompletionFragment,
                fragmentName: "QuestionWithoutCompletion"
              };

              const data = proxy.readFragment(frag);
              proxy.writeFragment({
                ...frag,
                data: {
                  ...data,
                  completed: true,
                  comments: [...data.comments, createAnnotationComment]
                }
              });
            } catch (err) {
              console.warn("error writing QuestionWithoutCompletionFragment");
            }
          }
        },
        optimisticResponse: {
          __typename: "Mutation",
          createAnnotationComment: {
            __typename: "Comment",
            id: Math.random(),
            createdBy: {
              __typename: "User",
              id: this.props.viewer.id,
              name: this.props.viewer.name,
              photo: this.props.viewer.photo
            },
            resource: attachments.resource,
            image: attachments.image
              ? {
                  id: attachments.image.id,
                  __typename: "Image",
                  url: attachments.image.url,
                  original_filename: attachments.image.original_filename
                }
              : null,
            content: val,
            createdAt: new Date(),
            enrollmentId: isCoach ? null : this.props.enrollmentId,
            commentable: this.props.commentable,
            commentableId: this.props.annotation.id,
            likedByViewer: false,
            ratingCount: 0
          }
        },
        variables: {
          input: {
            imageId: attachments.image ? attachments.image.id : null,
            userId: this.props.viewer.id,
            groupId: this.props.groupId,
            resourceId: attachments.resource ? attachments.resource.id : null,
            content: val,
            enrollmentId: isCoach ? null : this.props.enrollmentId,
            commentable: this.props.commentable,
            commentableId: this.props.annotation.id
          }
        }
      });

      const el = document.getElementById(
        res.data.createAnnotationComment.__typename +
          res.data.createAnnotationComment.id
      );
      if (el) {
        // el.scrollIntoView({
        //   block: "center",
        //   behavior: "smooth"
        // });
        scrollIntoView(el, {
          duration: 250,
          boundary: document.getElementById("scroll-box")
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({ content: text });
      Raven.captureException(err);
      notify.send({
        message:
          "An error occurred while posting your comment. Please try again.",
        type: "danger"
      });
    }
  };

  onChange = content => {
    this.setState({ content });
  };

  reply = e => {
    if (e) e.preventDefault();
    if (this.props.onReply) {
      return this.props.onReply();
    }

    this.setState({ content: Editor.createEmpty(), replying: true }, () => {
      if (this.replyBox) {
        // this.replyBox.scrollIntoView({
        //   block: "center",
        //   behavior: "smooth"
        // });
        scrollIntoView(this.replyBox, {
          duration: 250,
          boundary: document.getElementById("scroll-box")
        });
      }
    });
  };

  unlike = async () => {
    log("unlike");
    const isComment = this.props.size === "small"; // I need something better semantically here
    try {
      const optimistic = {
        __typename: "Mutation",
        [isComment ? "unlikeComment" : "unlikeAnnotation"]: {
          ...this.props.annotation,
          likedByViewer: false,
          ratingCount: this.props.annotation.ratingCount - 1
        }
      };

      if (isComment) {
        await this.props.unlikeComment({
          variables: {
            commentId: this.props.annotation.id
          },
          optimisticResponse: optimistic
        });
      } else {
        await this.props.unlikeAnnotation({
          variables: {
            annotationId: this.props.annotation.id
          },
          optimisticResponse: optimistic
        });
      }
    } catch (err) {
      console.error(err);
      Raven.captureException(err);
    }
  };

  like = async e => {
    e.preventDefault();

    if (this.props.annotation.likedByViewer) {
      return this.unlike();
    }

    log("like");
    const isComment = this.props.size === "small"; // I need something better semantically here

    try {
      const optimistic = {
        __typename: "Mutation",
        [isComment ? "likeComment" : "likeAnnotation"]: {
          ...this.props.annotation,
          likedByViewer: true,
          ratingCount: this.props.annotation.ratingCount + 1
        }
      };

      if (isComment) {
        await this.props.likeComment({
          variables: {
            commentId: this.props.annotation.id
          },
          optimisticResponse: optimistic
        });
      } else {
        await this.props.likeAnnotation({
          variables: {
            annotationId: this.props.annotation.id
          },
          optimisticResponse: optimistic
        });
      }
    } catch (err) {
      console.error(err);
      Raven.captureException(err);
    }
  };
}

const AnnotationWithFunctions = compose(
  graphql(CREATE_COMMENT, {
    name: "createComment"
  }),
  graphql(LIKE_ANNOTATION, { name: "likeAnnotation" }),
  graphql(LIKE_COMMENT, { name: "likeComment" }),
  graphql(UNLIKE_ANNOTATION, { name: "unlikeAnnotation" }),
  graphql(UNLIKE_COMMENT, { name: "unlikeComment" }),
  graphql(UPDATE_COMMENT, { name: "updateComment" }),
  graphql(UPDATE_ANNOTATION, { name: "updateAnnotation" })
)(Annotation);

class AnnotationContent extends React.Component {
  render() {
    return (
      <Message truncate={this.props.truncate} message={this.props.content} />
    );
  }
}

const ShowMoreButton = styled(IconButton)`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1px;
`;

export default AnnotationWithFunctions;
