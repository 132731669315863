import React from "react";
import { withFormik } from "formik";
import { Box, Alert, Input, Button, Modal, LayerLoading, Switch  } from "../../Kit";

import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { defaults } from "lodash";
import debug from "debug";
import * as yup from "yup";
import PropTypes from "prop-types";
import Raven from "raven-js";
import {
  UpdateLinkResourceMutation,
  CreateLinkResourceMutation
} from "../../API/mutations/LinkMutations";

const log = debug("app:AddResource");

const ResourceForm = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => (
  <form id="formId" onSubmit={handleSubmit}>
    <Modal.Body>
      <Input
        label="Title *"
        name="title"
        onChange={handleChange}
        error={errors.title}
        onBlur={handleBlur}
        value={values.title}
      />
      <Input
        label="Short description *"
        name="description"
        onChange={handleChange}
        error={errors.description}
        onBlur={handleBlur}
        value={values.description}
      />

      <Input
        label="URL *"
        name="url"
        onChange={handleChange}
        error={errors.url}
        onBlur={handleBlur}
        value={values.url}
      />

      <Switch
        label="This is a survey URL"
        checked={values.isSurvey}
        name="url"
        id="isSurvey"
        onChange={handleChange}
      />

      {errors.general && (
        <Box my={2}>
          <Alert type="danger">{errors.general}</Alert>
        </Box>
      )}
    </Modal.Body>

    <Modal.Footer pb={2}>
      <Button appearance="primary" type="submit" disabled={isSubmitting}>
        Submit
      </Button>
    </Modal.Footer>
    <LayerLoading loading={isSubmitting} />
  </form>
);

const formConfig = {
  mapPropsToValues: props => {
    let existing = props.resource ? props.resource : {};

    if (existing.meta) {
      existing = {
        ...existing,
        ...existing.meta
      };
    }

    return defaults(existing, {
      title: "",
      description: "",
      url: "",
      isSurvey: null
    });
  },
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("A title is required."),
    description: yup
      .string()
      .trim()
      .required("A description is required."),
    url: yup
      .string()
      .url("A valid URL is required.")
      .trim()
      .required("A valid URL is required.")
  }),
  handleSubmit: async (
    values,
    { props, resetForm, setSubmitting, setErrors }
  ) => {
    const attr = {
      input: {
        title: values.title,
        description: values.description
      },
      url: values.url,
      isSurvey: values.isSurvey
    };

    // handle updates
    if (props.resource) {
      attr.id = props.resource.id;
    } else {
      attr.input.courseId = props.courseId;
    }

    log("saving: %o", attr);

    try {
      const res = await props.mutate({ variables: attr });
      log("mutation response: %o", res);

      resetForm();

      if (props.onRequestClose) {
        props.onRequestClose();
      }
    } catch (err) {
      console.error(err);
      setErrors({ general: err.message });
      Raven.captureException(err);
    }

    setSubmitting(false);
  }
};

ResourceForm.propTypes = {
  episodeId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

const withCreateLink = graphql(CreateLinkResourceMutation, {
  options: {
    refetchQueries: ["CourseResources"]
  }
});

export const AddResource = compose(
  withCreateLink,
  withFormik(formConfig)
)(ResourceForm);

export const AddLink = AddResource;

const withUpdateLink = graphql(UpdateLinkResourceMutation);

export const UpdateLink = compose(
  withUpdateLink,
  withFormik(formConfig)
)(ResourceForm);
