import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Raven from "raven-js";

import { Helmet } from "react-helmet";

import auth from "../../Auth/auth";

import {
  Navbar,
  FooterComponent,
  FlexButtons,
  FlexButton,
  FlexButtonsBottom,
  FlexButtonBottom,
} from "../index";
import { onPhone, onSmallScreen, onSmallDesktop } from "../Elements";

import GLOBAL_CONSTANTS from "../../constants/config";
import Popover from "./popover";

const Container = styled.div`
  max-width: 1440px;
  // padding: 64px 32px;
  margin: 0 auto;

  ${onPhone} {
    // padding: 32px 16px;
  }
  ${onSmallDesktop} {
    max-width: 1100px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const FlexColumn = styled(Flex)`
  flex-direction: column;
`

const CardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  background: #c4c4c41a;
`;

const GroupContainer = styled(Flex)`
  justify-content: start;
  max-width: 1200px;
  margin: 2rem auto;
  width: 100%;
  height: 50rem;
  text-align: center;
  border-radius: 5px;
  flex-direction: column;
  border: 2px solid #f9f9f9;
  background: white;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 7%);
  ${onPhone} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
  ${onSmallScreen} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
`;

const ProgramCard = styled.div`
  width: 30%;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 13%);
  padding: 2rem;
  height: 40rem;
  text-align: center;
  border-radius: 5px;
  background: #ffffff;
  ${onPhone} {
    margin: 20px;
    width: 100%;
  }
  ${onSmallScreen} {
    margin: 20px;
    width: 70%;
  }
`;

const GroupText = styled.div`
  font-size: 20px;
  font-family: "poppins-bold";
  padding: 2rem 0;
  color: #f5821e;
`;

const Header = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
`;

const Price = styled.div`
  font-family: "poppins-bold";
  font-size: 30px;
  color: #f5821e;
`;

const PriceDesc = styled.div`
  font-style: italic;
`;

const ProgramDesc = styled.div``;

const HorizontalLine = styled.hr`
  margin: 2rem 0;
  height: 2px;
  color: ${(props) => props.color};
  border: none;
`;

const ProgramBeneficiariesPoint = styled.div`
  display: list-item;
  list-style-type: disc;
  position: relative;
  left: 7%;
`;

const ProgramBeneficiaries = styled.div`
  font-size: 12px;
  color: #515151;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 200px;
  justify-content: start;
  margin: 10px auto;
  text-align: start;
`;

const CenterButton = styled.div`
  margin: 1rem 0;
  background: ${(props) => props.color};
  padding: 10px;
  font-family: "poppins-bold";
  color: #575757;
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const FirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  height: 35rem;
  ${onPhone} {
    flex-direction: column;
    height: 100%;
  }
  ${onSmallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const FirstSectionText = styled.div`
  font-size: 24px;
  padding: 0 4rem;
  width: 50%;
  color: #000000;
  ${onPhone} {
    width: 100%;
    padding: 2rem 4rem;
  }
  ${onSmallScreen} {
    width: 100%;
    padding: 2rem 4rem;
  }
`;

const PlayBtn = styled.img`
  max-height: 8rem;
  position: relative;
  top: -19rem;
  left: 20rem;
  opacity: 0.8;
  ${onPhone} {
    top: -14rem;
    left: 10rem;
  }
`;

const ReviewButton = styled.div`
  color: white;
  background: #f5821e;
  padding: 15px 30px;
  border-radius: 40px;
  // font-size: 24px;
  width: fit-content;
  margin-top: 3rem;
  cursor: pointer;
  font-size: 22px;
  a {
    text-decoration: none;
    color: white;
  }
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const WatchNowSection = styled(Flex)`
  ${onPhone} {
    display: none;
  }
`;

const WatchNow = styled.div`
  margin-right: -30px;
  font-size: 12px;
  color: black;
  font-weight: bold;
`;

const WhyVTSImage = styled.img`
  border-radius: 10px;
  max-width: 650px;
  ${onPhone} {
    max-width: 100%;
  }
`;

const OfferingsImage = styled.img`
  border-radius: 5px;
  max-width: 650px;
  ${onPhone} {
    max-width: 100%;
  }
`;

const PointsMarker = styled.div`
  height: 22px;
  width: 3px;
  border-right: 3px solid #f5821e;
  margin-right: 1rem;
`;

export default function Program(props) {
  let [redirectToReferer, setRedirectToReferer] = useState(false);
  let [websiteURLs, setWebsiteURLs] = useState({
    program_page_videos: {},
    typeform_urls: {},
  });
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    fetch(GLOBAL_CONSTANTS.WEBSITE_URLS)
      .then((res) => res.json())
      .then((result) => {
        setWebsiteURLs(result);
      });
    setTimeout(() => {
      setShowPopover(true);
    }, 5000);
  }, []);

  let login = (provider) => {
    return async () => {
      const subscribe = false;

      try {
        await auth.authenticate(provider, subscribe);
        setRedirectToReferer(true);
      } catch (err) {
        if (err && err.error && err.error.code === "cancelled") {
        }

        Raven.captureException(err);
        console.error("Error", err);
      }
    };
  };

  if (redirectToReferer) {
    return <Redirect to="/" />;
  }

  function programClicked(label) {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "Program page",
        action: "Program selected",
        label: `Program ${label}`,
        value: "true",
      },
    });
  }

  return (
    <div
      className="App"
      style={{
        background: "white",
        fontFamily: "poppins-regular",
        color: "#515151",
      }}
    >
    
      <Helmet
        title="VTS K12 School program | Visual Thinking Strategies curriculum and professional development"
        meta={[
        {
          name: "title",
          content: "VTS K12 School program",
        },
        {
          name: "description",
          content: "Visual Thinking Strategies curriculum and professional development"
        }
        ]}
      />
      <Navbar login={login} {...props} />
      {/* <div style={{maxWidth: "1200px", margin: "3rem auto 1rem"}}>
        <div style={{fontSize: "40px", fontFamily: "poppins-bold"}}>Teacher and School Subscriptions</div>
        <div style={{fontSize: "25px"}}>Enrich every classroom with rich discussion and responsive instruction through Visual Thinking Strategies.</div>
      </div> */}
      { showPopover ? <Popover /> : null }
      <div style={{ background: "rgba(159,226,214,0.3)", padding: "3rem 0" }}>
        <Container>
          <FirstSection>
            <FirstSectionText>
              <div style={{ color: "#575757", fontSize: "42px" }}>
              VTS PK-12 School Program
              </div>
              <div style={{ padding: "10px 0" }}>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Student-centered{" "}
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Teacher-driven
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Deeper learning
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    fontSize: "20px"
                  }}
                >
                  Review videos and research <a style={{color: "#f5821e"}} color="#f5821e" href="https://www.watershed-ed.org/schools/visual-teaching-strategies/intro-to-visual-thinking-strategies-vts" target="_blank">here</a>.
                </div>
              </div>
            </FirstSectionText>
            <iframe
              src={websiteURLs.program_page_videos.general}
              width="650"
              height="370"
              style={{
                display: "block",
                maxWidth: "100%",
                borderRadius: "10px",
              }}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </FirstSection>
        </Container>
      </div>
      <Flex style={{ maxWidth: "1200px", margin: "0 auto", padding: "4rem 0" }}>
        <FlexColumn style={{ width: "75%", textAlign: "center" }}>
          <div style={{ fontSize: "20px", color: "#000" }}>
            <p>
              <div style={{color:"#f5821e", fontSize: "28px", paddingBottom: "10px"}}><b>Begin Your VTS Journey Today!</b></div>
            </p>
            
            <p>With introductory workshops scheduled throughout the year and a 100% virtual and highly flexible adoption model, educators can 
              now start their VTS practice at any time, from anywhere
            </p>
            
            <p style={{color: "#f5821e"}}>
              <a style={{color: "#f5821e"}} color="#f5821e"  
                href="https://bsgouuxlaee.typeform.com/to/IDzqFaDl?typeform-source=www.watershed-ed.org" 
                target={"_blank"}>Learn More</a> about our Pilot Package for teams of 2-5
            </p>
          </div>
          <FlexButtons>
            <FlexButtonBottom style={{marginRight: "2rem"}} color="#f5821e">
              <a
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "Program page",
                      action: "PDF download clicked",
                      label: "Review program details",
                      value: "true",
                    },
                  });
                }}
                style={{ textDecoration: "none" }}
                href={GLOBAL_CONSTANTS.PROGRAM_MODEL_URL}
                download
                target={"_blank"}
              >
                Review Details
              </a>
            </FlexButtonBottom>
            <FlexButtonBottom first="true" borderColor="#f5821e">
            <a
              onClick={() => programClicked("Training and Curriculum")}
              style={{ textDecoration: "none" }}
              href="https://bsgouuxlaee.typeform.com/to/IDzqFaDl?typeform-source=www.watershed-ed.org"
              target={"_blank"}
            >
              Attend Workshop
            </a>
          </FlexButtonBottom>
          </FlexButtons>
          
        </FlexColumn>
      </Flex>
      {/* cards */}
      <CardsContainer>
        <GroupContainer style={{ flexDirection: "column" }}>
          <GroupText>Comprehensive Packages</GroupText>
          <Flex style={{ justifyContent: "space-around", width: "90%" }}>
            <ProgramCard>
              <div style={{ height: "85%" }}>
                <Header>Individuals</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$200</Price>
                  <PriceDesc>per teacher</PriceDesc>
                </div>
                <ProgramBeneficiaries><b>Designed for:</b> Any PK-12 classroom teacher or specialist</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>All VTS PK-12 Student Image Sets & Library</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Live online Introduction to VTS Workshop</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Self-paced VTS Basics PD Modules</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Peer-based VTS Observation and Reflection Tools</ProgramBeneficiariesPoint>
                </ProgramBeneficiaries>
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  {/* Enrollment Open */}
                </div>
                <a
                  style={{ color: "#575757", textDecoration: "none" }}
                  onClick={() => programClicked("Individuals")}
                  href="https://events.zoom.us/eo/Amo35wT1Sxm9gb2klhUqGqIJHig4pqHLB4syU691O1c7sUZW-8cY~AggLXsr32QYFjq8BlYLZ5I06Dg"
                  target={"_blank"}
                >
                <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                  Enroll Today
                </CenterButton>
                </a>
              </div>
            </ProgramCard>
            <ProgramCard>
              <div style={{ height: "85%" }}>
                <Header>Teams and Schools</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$3,000</Price>
                  <PriceDesc>for up to 20 teachers</PriceDesc>
                  {/* <Price>$150</Price> */}
                  <PriceDesc>$150 per additional</PriceDesc>
                </div>
                {/* <ProgramDesc>Best for larger teams ready to adopt and implement VTS</ProgramDesc> */}
                <ProgramBeneficiaries><b>Designed for:</b> School-wide adoptions or multi-site cohorts</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>All that is included in the 'Individuals' package</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Custom Introduction to VTS Workshop scheduled at preferred time and date</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Dedicated program liaison and quarterly planning meetings</ProgramBeneficiariesPoint>
                </ProgramBeneficiaries>
                {/* <div style={{ fontSize: "12px" }}><i>$150 per additional educator</i></div> */}
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  {/* Sign up today */}
                </div>
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={() => programClicked("Teams and Schools")}
                  href="http://bit.ly/vtsteamsregistration" target={"_blank"}
                >
                  <CenterButton color="#B2CCE9">Contact us</CenterButton>
                </a>
              </div>
            </ProgramCard>
            <ProgramCard>
              <div style={{ height: "85%" }}>
                <Header>Districts and Networks</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$10,000</Price>
                  <PriceDesc>for 100 teachers</PriceDesc>
                  {/* <Price>$100</Price> */}
                  <PriceDesc>$100 per additional</PriceDesc>
                </div>
                {/* <ProgramDesc>Best for individual beginners as well as veterans of VTS</ProgramDesc> */}
                <ProgramBeneficiaries><b>Designed for:</b> Small and large teams from across a district</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>All that is included in the 'Teams and Schools' package</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Up to five dedicated Introduction to VTS Workshop dates over 3-month interval</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Monthly VTS expert 'office hours' open to all participating teachers</ProgramBeneficiariesPoint>
                </ProgramBeneficiaries>
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  {/* Available Spring 2023 */}
                </div>
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={() => programClicked("Districts and Networks")}
                  href="https://bit.ly/30minuteswithVTS"
                  target={"_blank"}
                >
                  <CenterButton color="#9FE2D6">Schedule a Demo</CenterButton>
                </a>
              </div>
            </ProgramCard>
          </Flex>
        </GroupContainer>
        <GroupContainer style={{ flexDirection: "column", height: "45rem" }}>
          <GroupText>VTS PK-12 Student Image Curriculum</GroupText>
          <Flex style={{ justifyContent: "space-around", width: "90%" }}>
          <ProgramCard style={{ height: "35rem" }}>

              <div style={{ height: "75%" }}>
                <Header>Annual Subscription</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$100</Price>
                  <PriceDesc>per educator per year</PriceDesc>
                </div>
                {/* <ProgramDesc>Best for individual beginners as well as veterans of VTS</ProgramDesc> */}
                <ProgramBeneficiaries><b>Designed for:</b> Continuing practitioners</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>10 developmentally-based image sets for all grade-levels</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>30+ images per grade-level</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>400+ indexed image database</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Observation and reflection forms</ProgramBeneficiariesPoint>

                </ProgramBeneficiaries>
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  Now Available
                </div>
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={() => programClicked("Annual Subscription")}
                  href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-pk-12-student-image-curriculum-2023"
                  target={"_blank"}
                >
                  <CenterButton style={{background: "#f5821e", color: "#FFFFFF"}}>Subscribe Today</CenterButton>
                </a>
              </div>
            </ProgramCard>

            <div className="columns">
              <a target={"_blank"} href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-pk-12-student-image-curriculum-2023">
                <div className="column">
                <OfferingsImage src={require("../images/vts-annual-subscription-image.png")} />
                </div>
              </a>
            </div>
           

            {/* <ProgramCard>
              <div style={{ height: "75%" }}>
                <Header>Private Workshops</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$3,000</Price>
                  <PriceDesc>for up to 20 educators</PriceDesc>
                </div>
                <ProgramBeneficiaries><b>Designed for:</b> Subscribing groups that 
                  want workshops scheduled at their convenience</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>Private Introduction to VTS and Group Coaching Workshops</ProgramBeneficiariesPoint>
                </ProgramBeneficiaries>
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  Make workshops private
                </div>
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={() => programClicked("Private Workshops")}
                  href={websiteURLs.typeform_urls.pilot_vts}
                  target={"_blank"}
                >
                  <CenterButton color="#B2CCE9">
                    Contact us
                  </CenterButton>
                </a>
              </div>
            </ProgramCard>
            /*<ProgramCard>
              <div style={{ height: "75%" }}>
                <Header>One-Day Workshop</Header>
                <div style={{ margin: "30px 0" }}>
                  <Price>$3,000</Price>
                  <PriceDesc>for up to 20 educators</PriceDesc>
                </div>
                <ProgramBeneficiaries><b>Designed for:</b> Groups not participating in our core offerings</ProgramBeneficiaries>
                <ProgramBeneficiaries>
                  <div><b>Includes:</b></div>
                  <ProgramBeneficiariesPoint>Two planning meetings with our VTS trainer</ProgramBeneficiariesPoint>
                  <ProgramBeneficiariesPoint>Customized 6-hour training responsive to specific needs/context</ProgramBeneficiariesPoint>
                </ProgramBeneficiaries>
              </div>
              <div>
                <div style={{ color: "#f5821e", fontWeight: "600", marginTop: "2rem" }}>
                  Let's talk
                </div>
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={() => programClicked("One-Day Workshops")}
                  href={websiteURLs.typeform_urls.pilot_vts}
                  target={"_blank"}
                >
                  <CenterButton color="#B2CCE9">
                    Contact us
                  </CenterButton>
                </a>
              </div>
            </ProgramCard> */}
          </Flex>
        </GroupContainer>
      </CardsContainer>
      <Container style={{ padding: "4rem 4rem 4rem" }}>
        <div style={{ color: "#000000", fontSize: "32px" }}>
          Want to Learn More?
        </div>
        <FlexButtonsBottom style={{ maxWidth: "440px" }}>
          <div
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              window.Calendly.initPopupWidget({
                url: websiteURLs.calendly_url,
              });
              return false;
            }}
          >
            <FlexButtonBottom
              style={{ minWidth: "200px", fontSize: "20px" }}
            >
              Schedule a call
            </FlexButtonBottom>
          </div>
          <FlexButtonBottom
            first
            color="#ffffff"
            borderColor="#f5821e"
            style={{ minWidth: "200px", fontSize: "20px" }}
          >
            <a
              href={`mailto:hello@watershed-ed.org?bcc=bcc@nutshell.com&subject=I'd like to learn more about...`}
              target={"_blank"}
            >
              Ask us a question
            </a>
          </FlexButtonBottom>
        </FlexButtonsBottom>
      </Container>
      <Flex style={{ background: "#FFF8F4" }}>
        <Flex style={{ maxWidth: "1200px", margin: "3rem 1rem" }}>
          <div style={{ padding: "3rem 0" }}>
            <div style={{ color: "#000", fontSize: "70px" }}>
              Why <span style={{ color: "#f5821e" }}>VTS?</span>
            </div>
            <div style={{ margin: "1rem 6rem 0 0", fontSize: "22px" }}>
              <Link
                style={{ color: "#f5821e" }}
                to="/schools/visual-teaching-strategies/intro-to-visual-thinking-strategies-vts"
              >
                Create free account
              </Link>
              &nbsp;to see VTS in action, review research, and hear from more
              educators
            </div>
          </div>
          <div>
            <WhyVTSImage src={require("../images/why_vts.png")} />
          </div>
        </Flex>
      </Flex>
      <FooterComponent {...props} />
    </div>
  );
}
