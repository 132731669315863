import React, { useState } from "react";
import styled from "styled-components";
import Raven from "raven-js";
import { Redirect } from "react-router-dom";

import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";

import {
  Header,
  Text,
  onPhone,
  onSmallScreen,
} from "../Elements";
import auth from "../../Auth/auth";
import Timeline from "./timeline";

import { FlexColumn, FooterComponent, Navbar } from "../index";

import "./styles.css";

const Layout = ({ children }) => (
  <div
    style={{
      background: "white",
      fontFamily: "poppins-regular",
      color: "#515151",
    }}
  >
    {children}
  </div>
);

const Container = styled.div`
  max-width: 1440px;
  padding: 3rem 32px;
  margin: 0 auto;

  ${onPhone} {
    padding: 32px 16px;
  }
  ${onSmallScreen} {
    padding: 32px 16px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const Grid = styled.div`
  ${onPhone} {
    display: block !important;
    margin-top: 1rem !important;
    & * {
      padding-bottom: 1rem;
    }
  }
  // ${onSmallScreen} {
  //   display: block !important;
  //   margin-top: 1rem !important;
  //   & * {
  //     padding-bottom: 1rem;
  //   }
  // }
`;

const CalloutButton = styled.div`
  text-decoration: none;
  padding: 1rem 2rem;
  text-align: center;
  background: #f5821e;
  display: inline-block;
  border-radius: 30px;

  & a {
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 60%);
  }
`;

const ImpactSection = styled(Flex)`
  background: #e0ebf666;
  height: 70vh;
  color: #000;
  ${onPhone} {
    height: 100%;
  }
  ${onSmallScreen} {
    height: 100%;
  }
`;

const ImpactHeader = styled(Flex)`
  background: #e0ebf6;
  height: 100%;
  width: 30%;
  font-size: 70px;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 1rem;
  ${onPhone} {
    width: 100%;
  }
  ${onSmallScreen} {
    width: 100%;
  }
`;

const ImpactBody = styled(Flex)`
  width: 70%;
  height: 100%;
  padding: 3rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 18px;
`;

const ImageWrap = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
  width: 100px;
  &:hover {
    box-shadow: 0px 2px 11px 5px rgb(0 0 0 / 60%);
  }
`;

const TeamHeader = styled.div`
  font-size: 54px;
  text-align: center;
  color: #f5821e;
  margin-bottom: 4rem;
`;

const MembersContainer = styled(Flex)`
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: start;
`;

const MemberDescBox = styled.div`
  width: 300px;
  padding: 10px;
  font-size: 12px;
`;

const MemberImg = styled.img``;

const CloseBtn = styled.div`
  float: right;
  margin: 0 -15px;
  margin-top: -5px;
  cursor: pointer;
`;

const NewsContainer = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 1rem;
  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const NewsletterText = styled.div`
  font-size: 30px;
  padding-bottom: 3rem;
  max-width: 500px;
`;
const AboutUs = (props) => {
  let [redirectToReferer, setRedirectToReferer] = useState(false);
  let [memberDesc, setMemberDesc] = useState(null);
  let [showMemberDesc, setshowMemberDesc] = useState(false);

  let [bMemberDesc, setBMemberDesc] = useState(null);
  let [showBMemberDesc, setshowBMemberDesc] = useState(false);

  let displayOnHover = (memberId, type) => {
    if (type === 0) {
      // team member
      setMemberDesc(memberId);
      setshowMemberDesc(true);
    } else {
      // team member
      setBMemberDesc(memberId);
      setshowBMemberDesc(true);
    }
  };

  let hideOnHover = () => {
    setMemberDesc(null);
    setshowMemberDesc(false);
    setBMemberDesc(null);
    setshowBMemberDesc(true);
  };

  let login = (provider) => {
    return async () => {
      const subscribe = false;

      try {
        await auth.authenticate(provider, subscribe);
        setRedirectToReferer(true);
      } catch (err) {
        if (err && err.error && err.error.code === "cancelled") {
        }

        Raven.captureException(err);
        console.error("Error", err);
      }
    };
  };

  if (redirectToReferer) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Helmet title="About | VTS at Watershed">
        <meta name="title" content="About | VTS at Watershed" />
        <meta name="description" content="About | VTS at Watershed" />
      </Helmet>
      <Navbar {...props} login={login} />
      <div style={{ background: "#E2F6F3" }}>
        <Container>
          <Grid
            style={{
              display: "grid",
              gridTemplateColumns: "60% 40%",
              gridColumGap: "2rem",
            }}
          >
            <div>
              <Header>About Us</Header>
              <Text>
                <div style={{ marginBottom: "1rem" }}>
                  We founded Watershed Collaborative to address a tangible
                  problem that beset us for more than two decades: VTS remained
                  out of the reach of most teachers. Ample evidence proved the
                  effectiveness of VTS as a method of teaching and learning, a
                  P-12 curriculum, and a professional development program.
                  Still, dissemination of VTS was slow and expensive.
                </div>
                <div>
                  This means that too few young people—and teachers—benefit from
                  the profound impact VTS can have on achieving the goals of
                  education. We aim to change that.
                </div>
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div>
                  <img
                    alt="Watershed"
                    src={require("../images/grey_watershed_logo.png")}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
      <Container
        style={{
          textAlign: "center",
          maxWidth: "1030px",
          marginBottom: "4rem",
        }}
      >
        <div style={{ fontSize: "54px", color: "#000" }}>
          Our <span style={{ color: "#f5821e" }}>Mission</span>
        </div>
        <Header style={{ fontSize: "32px", color: "#000" }}>
          Make VTS possible in any classroom in the US and abroad. To do so, we
          offer a highly-accessible and affordable portfolio of products and
          services designed for professional learning and instruction in 21st
          century classrooms.
        </Header>
      </Container>
      <Container>
        <ImpactSection>
          <ImpactHeader>
            The <br />
            Evolution of
            <br />
            <span style={{ color: "#f5821e" }}> VTS</span>
          </ImpactHeader>
          <ImpactBody>
            <div>
              Thirty years ago, Philip Yenawine and Abigail Housen met while
              Yenawine was at MoMA in New York and Housen was a cognitive
              psychologist (Ed.D., Harvard GSE) studying how people think when
              they look at art. Visual Thinking Strategies, or VTS, was born of
              their collaboration.
            </div>
            <div>
              Over the ensuing years, as Housen and others learned of and proved
              VTS’ impact through multiple longitudinal studies, what began as a
              new way to teach in museums has steadily become indispensable to
              PreK-12 classrooms.
            </div>
            <div>
              Historically, schools have adopted VTS for its way of engaging all
              students, and its impact on thinking, language, and socio-emotional
              skills. Schools increasingly view VTS as a way of re-infusing the
              arts in every classroom, closing the achievement gap, and meeting
              otherwise hard-to-achieve state instructional standards.
            </div>
            <div>
              Watershed was founded to make VTS more accessible, affordable, and
              effective for any P-12 educator in the U.S. or abroad.
            </div>
          </ImpactBody>
        </ImpactSection>
      </Container>
      <Container
        style={{ textAlign: "center", maxWidth: "700px", padding: "3rem" }}
      >
        <Header style={{ fontSize: "32px" }}>
          Support our work today.
        </Header>
        <a
          style={{ color: "white" }}
          href="https://donorbox.org/vts-watershed-curriculum-expansion-2023-2024"
        >
          <CalloutButton>Donate Now</CalloutButton>
        </a>
      </Container>
      <div style={{background: "#FFF8F4"}}>
        <Container>
          <div style={{padding: "3rem" }}>
            <Header
              style={{ marginBottom: "0", fontWeight: "600", color: "#f5821e" }}
            >
              Our History
            </Header>
            <Header style={{ color: "#000" }}>Decades in the making</Header>
            <Timeline />
          </div>
        </Container>
      </div>
      <div style={{margin: "3rem 0"}}>
        <TeamHeader>Our Team</TeamHeader>
        <MembersContainer>
          {[
            {
              id: 2,
              image: require("../images/profiles/lynn.jpeg"),
              name: "Lynn Canzano Pyfer",
              short:
                "With a background in poverty research, after-school and summer camp operations, and data and evaluation analysis linking programmatic efforts to participant outcomes, Lynn Canzano Pyfer brings a multifaceted skill set and a mindset couched in designing for under-resourced communities.",
              description:
                "As Operations Director, Lynn coordinates both day-to-day administrative management and longer-term strategy to make all our moving parts work in concert.  With more than 10 years experience in the nonprofit sector, she brings strong organizational, programmatic, and evaluative skills to the position.  Lynn earned a BA from the University of Notre Dame and an MUP from New York University’s Wagner School of Public Service.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/lynn-pyfer-42368910",
                },
              ],
            },
            {
              id: 1,
              image: require("../images/profiles/nick.jpeg"),
              name: "Nick Gardner",
              short:
                "Nick Gardner, who for years embedded with school districts and teachers nationwide to support student-centered improvement efforts, brings a vision for integrating practical strategies and digital infrastructure that enable more effective, efficient, and integral adoption of student-centered methods.",
              description:
                "As Executive Director, Nick leads efforts to collaborate with innovators, schools, and districts, and coordinates product development and the teacher engagement strategy.  He leverages strong relationships with education leaders and administrators country-wide, which he built during more than 8 years of experience at VTS.  Nick earned his BA from Webster University and an MPA from New York University’s Wagner School of Public Service.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/nicholasdgardner",
                },
              ],
            },
            {
              id: 3,
              image: require("../images/profiles/philip.jpeg"),
              name: "Philip Yenawine",
              short:
                "Nationally-recognized thought leader, Philip Yenawine, revolutionized museum education when he developed an inquiry-based teaching protocol called Visual Thinking Strategies (VTS) decades ago at New York’s Modern Museum of Art.",
              description:
                "As a published author, he continues to champion inquiry as the path to teacher and student success.  As Creative Director, Philip leads development of teacher training content and inquiry-based applications and resources for enhancing learning.  He brings to this task more than 30 years of experience developing, researching, and disseminating the VTS protocol.  Philip attended Princeton University, and holds a BA from Governor’s State University in Park Forest South, IL, and an MA from Goddard College in Plainfield, VT.  He was awarded an honorary doctorate by the Kansas City Art Institute in 2003.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/philip-yenawine-6279455",
                },
              ],
            },
          ].map((member) => (
            <FlexColumn style={{ width: "33%", margin: "5rem 0" }}>
              <ImageWrap>
                <MemberImg
                  style={{
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                  src={member.image}
                  data-tip
                  data-for={member.name}
                  data-event='click focus'
                />
                <ReactTooltip
                  id={member.name}
                  className="customTheme"
                  effect="solid"
                  delayHide={300}
                  globalEventOff="click"
                >
                  <MemberDescBox>
                    <CloseBtn>
                      {" "}
                      <img src={require("../images/close_btn.svg")} />{" "}
                    </CloseBtn>
                    <div style={{ fontSize: "14px", marginBottom: "2rem" }}>
                      <b>{member.name}</b>
                    </div>
                    <div style={{ marginBottom: "1.5rem" }}>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                </ReactTooltip>
              </ImageWrap>
              <div>{member.name}</div>
              <div>
                <a href={member.links[0].to} target={"_blank"}>
                  <img src={require("../images/linkedin.png")} />
                </a>
              </div>
              {/* {member.id === memberDesc && showMemberDesc && (
                  <MemberDescBox>
                    <div>
                      <b>{member.name}</b>
                    </div>
                    <div>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                )} */}
            </FlexColumn>
          ))}
        </MembersContainer>
      </div>

      <div>
        <TeamHeader>Our Board</TeamHeader>
        <MembersContainer>
          {[
            {
              image: require("../images/profiles/michael.jpg"),
              name: "Michael Gabriel",
              short:
                "Michael Gabriel is a senior level technology advisor and partner with Fortium Partners, LP.  He was previously Executive Vice President & CIO for Home Box Office for 14 years, and a founding executive leader of the media and entertainment industry transforming HBO GO Initiative. He was the technology leader on the Time Warner Information Risk Committee, and the CIO sponsor of the Time Warner Cyber Security Committee.",
              description:
                "Prior to HBO, he was Vice President & CIO for the National Basketball Association (NBA), Vice President of IT for the EMI-Capitol Music Group NA, and Director of IT for the 1992 Barcelona Olympics and Director of Financial Systems at the National Broadcasting Company (NBC)",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/michael-gabriel-cio",
                },
              ],
            },
            {
              image: require("../images/profiles/justin.png"),
              name: "Justin Hwa",
              short:
                "Justin Hwa is a business leader, corporate innovator, and investor, with deep experience across Europe, the Middle East, Asia Pacific, and North America. He has built, scaled, and led numerous teams and businesses in the tech, marketplaces, ecommerce, and finance fields through his expertise in product & development, strategy, mergers & acquisitions, strategic partnerships, and general management and P&L ownership.",
              description:
                "Most recently, Justin leads eBay’s Commerce Innovations venture studio, based in Berlin. The studio is focused on launching new platforms, products, and businesses that augment and enhance the shopping experience on- and off-eBay. Prior to running Commerce Innovations, Justin led eBay’s M&A and Strategic Partnerships functions for EMEA. He was also an investment banker at Moelis & Company based in New York and Hong Kong, specializing in M&A and restructuring for Fortune 500 companies in Asia Pacific, Europe, and North America.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/justinhwa/",
                },
              ],
            },
            {
              image: require("../images/profiles/nived.jpeg"),
              name: "Nived Kollanthara",
              short:
                "A Senior Associate on the Corporate Strategy team at Cognizant. In his current role, he is responsible for tackling challenges across long-term global strategy definition (competitive and growth strategy), strategic projects (transformation initiatives, M&A strategy and support), and business unit strategy support and guidance. Nived also serves as Cognizant’s head of community outreach for NYC",
              description:
                "Nived is also the co-founder of Kanak, a free AI-based math practice platform that provides teachers with deep insights about their students’ performance and engagement in remote learning environments.  In addition to the Watershed Collaborative, Nived acts as an advisor to several non-profits in NYC and Connecticut tackling problems across STEM education for underprivileged youth, environmental sustainability, and homelessness. He holds a Bachelor of Applied Science in Industrial Engineering degree from the University of Toronto.",
              links: [
                {
                  type: "LinkedIn",
                  to:
                    "https://www.linkedin.com/in/nived-kollanthara/",
                },
              ],
            },
            {
              image: require("../images/profiles/selena.jpg"),
              name: "Selena Meere",
              short:
                "Selina has more than two decades experience as a strategic leader who built distinguished marketing and communication teams within New York’s book publishing industry.",
              description:
                "After relocating to Los Angeles, she developed the national brand, voice, and salesforce for an award winning fintech startup and has positioned the narrative for a range of clients across entertainment and direct-to-consumer products. She is currently the Managing Director at Trevanna Tracks, a productivity and workflow SaaS tool for managing sync music licensing in film, television and video games, where she is overseeing all operational activities and driving growth strategy across the organization. She holds a Bachelors in Business from Gettysburg College, a Masters in Journalism from City University in London, and has studied marketing at the Kellogg School of Business at Northwestern",
              links: [
                {
                  type: "LinkedIn",
                  to:
                    "https://www.linkedin.com/in/selina-meere/",
                },
              ],
            },
            {
              image: require("../images/profiles/greer.png"),
              name: "Greer Meisels",
              short:
                "Board Member Greer Meisels is a foreign policy expert who has built her career working in geographically and culturally diverse organizations, both in the public and private sectors.",
              description:
                "Her work has taken her around the globe – to Latin America, Europe and the Asia-Pacific region. She is currently the Chief of Staff at the Institute of International Finance (IIF) where she also manages its Future Leaders Program, which was created to recognize young leaders within the financial industry and to foster ties between rising stars from different regions and backgrounds. Greer also works extensively on sustainable finance and ESG issues including how to scale up investment to achieve the UN Sustainable Development Goals. Prior to joining IIF, Greer held several positions in the International Affairs division of the U.S. Department of the Treasury, including as Senior Advisor to the Under Secretary of International Affairs. She was also a policy advisor focused on the U.S.-China economic relationship, on investment security issues, and on climate finance. Earlier in her career, she worked at several think tanks both in Washington, DC and New York and was a visiting lecturer at the China Foreign Affairs University, in Beijing.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/greer-meisels-8458257/",
                },
              ],
            },
            {
              image: require("../images/profiles/lilach.jpeg"),
              name: "Lilach Ohad",
              short:
                "Lilach Ohad is an impact focused leader with over 20 years’ experience creating measurable and sustainable change for individuals and communities. She currently consults Neki, a tech start-up, providing businesses and individuals with a simple way to support social causes.",
              description:
                "Prior to Neki, Lilach worked at Friends of IDF (FIDF), a non-profit organization, headquartered in NY with 25 chapters across the U.S. She held various position including Chief Operating Officer and Director of Projects and Programs. Throughout her career at FIDF, Lilach helped grow the company from a small operation to a sophisticated fundraising organization. She had a significant part in transforming FIDF into a key change maker in its field; one that makes a difference in the lives of tens of thousands of people each year. Lilach holds an MBA degree from MIT Sloan School of Management and a bachelor’s degree in Art History from Hunter College, City University of NY.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/lilach-ohad-44088b4",
                },
              ],
            },
            {
              image: require("../images/profiles/saneel.png"),
              name: "Saneel Pansare",
              short:
                "Board Member Saneel Pansare is a technologist and a key member of the technology leadership team within Shared Services business at Moody’s Corporation. He currently serves as Vice President, overseeing a global operations portfolio of Finance and Accounting, Human Resource (HR), Billing and Treasury systems. In this role, he is responsible for transforming operational environments thereby driving innovation, boosting productivity and improving effectiveness across Shared Services business units.",
              description:
                "He has also held several leadership positions at Moody’s overseeing Application Development for highly regulated credit ratings, research, content and data management systems, IT M&A Integrations and Divestitures, SaaS platforms for Service Management, Asset Management and Real Estate, IT Risk and Governance, and Robotic Process Automation. Prior to joining Moody’s, he held consulting position at Information Technology company Larsen & Toubro Infotech in Mumbai, India overseeing Fraud Management product build for one of the largest telecom provider. He holds a bachelor’s degree in Telecommunication from University of Mumbai and Executive Masters in Technology Management from Columbia University.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/saneel-pansare-1356727/",
                },
              ],
            },
            {
              name: "Catherine Purdon",
              image: require("../images/profiles/catherine.jpeg"),
              short:
                "Board member Catherine Purdon is an attorney and currently works with PwC as Data Protection Counsel.",
              description:
                "Prior to that, Catherine was counsel for the International Baccalaureate Organization in various capacities for over 10 years and at corporate law firms in New York and Boston. Catherine obtained her law degrees from McGill University and is admitted to practice in New York and Ontario.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/catherine-purdon-3497ba12/",
                },
              ],
            },
            {
              image: require("../images/profiles/sachin.PNG"),
              name: "Sachin Sharma",
              short:
                "Sachin is a business leader with deep expertise in the enterprise software sector. In particular, Sachin has spent years implementing and advising on large, value-added initiatives across corporate strategy, corporate development, go-to-market strategy, salesforce effectiveness, client success, and product management in the B2B software sector.",
              description:
                "Sachin is currently a Director of Product Management at Kibo, a Vista Equity Partners' portfolio company and software company in the digital commerce space. Prior to this, Sachin was the Director of Strategic Programs at Kibo, where he worked on the business' organic product strategy, go-to-market strategy, and led multiple acquisitions and acquisition integration programs for the company. Prior to Kibo, Sachin spent several years at Vista, working with management teams across the portfolio to design & implement value creation initiatives to improve sales, revenue retention, and business efficiency cross-functionally. Prior to joining Vista, Sachin worked at Stax, a strategy consulting firm that specializes in providing pre-acquisition commercial diligence services to the private equity industry. Sachin also spent time at Ubiquiti (NYSE: UI), where he reported to the CEO & Founder, working on strategy and operations. Sachin attended the University of Chicago, where he received his BA in Economics and Philosophy.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/sachin-sharma-10215841",
                },
              ],
            },
            {
              image: require("../images/profiles/jill.jpg"),
              name: "Jill Tanner",
              short:
                "Jill Tanner is a forward-thinking creative leader with over 30 years of experience in developing award-winning brand and marketing campaigns.",
              description:
                "A sought-after speaker for her work on diversity, equity and inclusion, mentorship programs, and designing experiential agency growth strategies, Jill is currently Senior Vice President at InVision Communications (IVC), an experiential brand agency headquartered in the San Francisco Bay Area. She leads IVC’s creative and marketing teams, and also drives strategic planning as a key member of the agency’s executive team. Since joining IVC in 2012, Jill has expanded the agency’s creative solutions, improved its processes, and developed its talent pool contributing to a 70% increase in revenue growth. In 2020, Jill led an internal task force to address the impact of Covid-19; under her leadership, the agency reversed an initial 41% revenue drop and successfully retained 90% of its employees. Also in 2020, Jill proudly developed the agency’s first DEI initiative focused on cultivating an inclusive culture, training employees, and building a more diverse talent pipeline. Prior to InVision, Jill spent more than 20 years at Hewlett Packard in various enterprise sales, operations, and global channel partner training positions before being tapped to drive brand experience on the corporate marketing team. A graduate of the University of Southern California, Jill holds degrees in Journalism and Sports Marketing. She also completed an Integrated Marketing Communication Executive Program through the Kellogg School of Management.",
              links: [
                {
                  type: "LinkedIn",
                  to: "https://www.linkedin.com/in/jill-tanner-1272467/",
                },
              ],
            },
            {
              image: require("../images/profiles/stephanie.jpg"),
              name: "Dr. Stephanie Varnon-Hughes",
              short:
                "Board Chair Dr. Stephanie Varnon-Hughes is the Director of Cross-Cultural & Interfaith Programs at Claremont Lincoln University.",
              description:
                " She is an award-winning teacher and interfaith leader whose research interests include the history, theories, and practices of inter-religious education, public policy, inequities in public education, and how digital and online resources can make education accessible and learner-focused. Her doctoral dissertation, in inter-religious education, focused on disequilibrium, resilience, and reflective practice as key ingredients for learning.  She was a co-founder and editor-in-chief of The Journal of Inter-Religious Studies, a peer reviewed journal, and its sister publication, State of Formation, an online forum for emerging religious and ethical leaders.  She holds a Ph.D. from Claremont Lincoln University, an M.A. and S.T.M. from Union Theological Seminary and her undergraduate degrees are in English and Education, from Webster University.",
              links: [
                {
                  type: "LinkedIn",
                  to:
                    "https://www.linkedin.com/in/stephanie-varnon-hughes-6a137311",
                },
              ],
            },
            
            
          ]
            .sort()
            .map((member) => (
              <FlexColumn style={{ width: "33%", margin: "5rem 0" }}>
                <ImageWrap>
                  <MemberImg
                    style={{
                      maxWidth: "100%",
                      cursor: "pointer",
                    }}
                    src={member.image}
                    data-tip
                    data-for={member.name}
                    data-event='click focus'
                  />
                  <ReactTooltip
                    place="left"
                    id={member.name}
                    className="customTheme"
                    effect="solid"
                    delayHide={300}
                    globalEventOff="click"
                  >
                    <MemberDescBox>
                      <CloseBtn>
                        {" "}
                        <img src={require("../images/close_btn.svg")} />{" "}
                      </CloseBtn>
                      <div style={{ fontSize: "14px", marginBottom: "2rem" }}>
                        <b>{member.name}</b>
                      </div>
                      <div style={{ marginBottom: "1.5rem" }}>
                        {member.short}
                      </div>
                      <div>{member.description}</div>
                    </MemberDescBox>
                  </ReactTooltip>
                </ImageWrap>
                <div>{member.name}</div>
                <div>
                  <a href={member.links[0].to} target={"_blank"}>
                    <img src={require("../images/linkedin.png")} />
                  </a>
                </div>
                {/* {member.id === bMemberDesc && showBMemberDesc && (
                  <MemberDescBox>
                    <div>
                      <b>{member.name}</b>
                    </div>
                    <div>{member.short}</div>
                    <div>{member.description}</div>
                  </MemberDescBox>
                )} */}
              </FlexColumn>
            ))}
        </MembersContainer>
      </div>

      <div style={{ background: "#E2F6F3" }}>
        <Container style={{paddingLeft: "0", maxWidth: "1200px"}}>
          <NewsContainer>
            <NewsletterText>
              Stay up to date on our work and the latest VTS offerings
            </NewsletterText>
            <CalloutButton>
              <a
                style={{ color: "white" }}
                href={`mailto:hello@watershed-ed.org?bcc=bcc@nutshell.com&subject=Please add me to the Watershed Newsletter`}
              >
                Join Our Newsletter
              </a>
            </CalloutButton>
          </NewsContainer>
        </Container>
      </div>
      <FooterComponent {...props}/>
    </Layout>
  );
};

export default AboutUs;
