import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./Auth/PrivateRoute";
import { ErrorBoundary } from "./Kit/ErrorCapture";
import Branding, { AboutUs } from "./Branding";
import NotFound from "./NotFound";
import AppLayout from "./AppLayout";
import loadable from "react-loadable";
import * as Async from "./RoutesAsync";
import BrandingLayout from "./Branding/BrandingLayout";
import LoadingIndicator from "./RouteLoading";
import SidebarLayout from "./SidebarLayout";
import Chat from "./Chat";
import { About, Course, Program, FourC } from "./Branding";

const RoutesAdmin = loadable({
  loader: () => import("./RoutesAdmin"),
  loading: LoadingIndicator,
});

const PrimaryRoutes = ({ auth }) => (
  <Router>
    <Switch>
      <Route path="/login" component={Async.Login} />
      <PrivateRoute path="/stripe" component={Async.Stripe} />
      <PrivateRoute path="/graphiql" component={Async.AsyncAPI} />
      {/* {process.env.NODE_ENV === "development" && (
      )} */}

      {!auth && (
        <Route
          path="/"
          exact
          render={() => (
            <BrandingLayout>
              <Branding />
            </BrandingLayout>
          )}
        />
      )}
      <Route
        component={FourCScrollToTop}
        path="/4c"
        exact
        render={() => (
          <BrandingLayout>
            <FourC />
          </BrandingLayout>
        )}
      />
      <Route
        component={ProgramScrollToTop}
        path="/program"
        exact
        render={() => (
          <BrandingLayout>
            <Program />
          </BrandingLayout>
        )}
      />
      <Route
        component={CourseScrollToTop}
        path="/course"
        exact
        render={() => (
          <BrandingLayout>
            <Course />
          </BrandingLayout>
        )}
      />

      <Route
        component={AboutScrollToTop}
        path="/aboutus"
        exact
        render={() => (
          <BrandingLayout>
            <AboutUs />
          </BrandingLayout>
        )}
      />
      <Route
        component={PublicHomeScrollToTop}
        path="/public"
        exact
        render={() => (
          <BrandingLayout>
            <Branding publicPage="true" />
          </BrandingLayout>
        )}
      />
      <Route
        component={PublicProgramScrollToTop}
        path="/public/program"
        exact
        render={() => (
          <BrandingLayout>
            <Program publicPage="true" />
          </BrandingLayout>
        )}
      />
      <Route
        component={PublicAboutScrollToTop}
        path="/public/aboutus"
        exact
        render={() => (
          <BrandingLayout>
            <AboutUs publicPage="true" />
          </BrandingLayout>
        )}
      />

      <RoutesWithLayout key="layout" auth={auth} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

const FourCScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <FourC />
    </BrandingLayout>
  );
};

const PublicHomeScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <Branding publicPage="true" />
    </BrandingLayout>
  );
}

const PublicProgramScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <Program />
    </BrandingLayout>
  );
};

const PublicAboutScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <AboutUs />
    </BrandingLayout>
  );
};


const ProgramScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <Program />
    </BrandingLayout>
  );
};

const AboutScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <AboutUs />
    </BrandingLayout>
  );
};

const CourseScrollToTop = () => {
  window.scrollTo(0, 0);
  return (
    <BrandingLayout>
      <Course />
    </BrandingLayout>
  );
};

const RoutesWithLayout = ({ auth }) => (
  <ErrorBoundary>
    <AppLayout auth={auth}>
      <ErrorBoundary>
        <Switch>
          <Route
            path="/schools/:publisherSlug/:courseSlug"
            render={(props) => <Async.Course {...props} />}
          />
          <Route
            path="/schools/:slug"
            exact
            render={(props) => (
              <Async.Publisher publisherSlug={props.match.params.slug} />
            )}
          />
          <PrivateRoute path="/admin" exact component={Async.PublisherList} />
          <PrivateRoute
            path="/admin/schools/:publisherSlug"
            render={(props) => {
              return <RoutesAdmin {...props} />;
            }}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Redirect
                to={{
                  pathname: "/dashboard",
                }}
              />
            )}
          />
          <Route
            exact
            path="/dashboard"
            render={(props) => {
              return (
                <SidebarLayout>
                  <Async.Dashboard {...props} />
                </SidebarLayout>
              );
            }}
          />
          <PrivateRoute path="/chat" exact component={Chat} />

          <PrivateRoute
            path="/coach/:sectionSlug"
            render={(props) => {
              return <Async.Coach {...props} />;
            }}
          />
          <PrivateRoute path="/settings" component={Async.Settings} />
          
          {/* Route to after enrolled in the course */}
          <PrivateRoute
            path="/learn/:id/:enrollmentId"
            component={Async.Group}
          />
          <PrivateRoute path="/invitations/:key" component={Async.Invitation} />
          <PrivateRoute
            path="/school-invitations/:key"
            component={Async.PublisherInvitation}
          />
          <Route component={NotFound} />
        </Switch>
        <Async.ViewEpisodeRoute />
      </ErrorBoundary>
    </AppLayout>
  </ErrorBoundary>
);

export default PrimaryRoutes;
