import React from "react";
import Media from "react-media";
import Box from "../Kit/Box";
import Flex from "../Kit/Flex";
import Button from "../Kit/Button";
import { NavbarBrand, NavbarLinkStyled } from "../Kit/Nav";
import Text from "../Kit/Text";
import Dropdown from "../Kit/Dropdown";
import IconButton from "../Kit/IconButton";
import Avatar from "../Kit/Avatar";
import { NavbarLink } from "../Kit/Nav";
import { Query } from "react-apollo";
import UnreadMessages from "./UnreadMessages";
import authController from "../Auth/auth";
import { Badge } from "../Kit/Badge";
import ContentLoader from "react-content-loader";
import EventDropdown from "./EventDropdown";
import Popover from "../Kit/Popover";
import PushNotifications from "../PushNotifications";
import UpdateRead from "../EventFeed/UpdateRead";
import { ViewerQuery } from "../API/queries/ViewerQuery";
import {NavLink} from "../Branding/Elements";

const NavDivider = () => (
  <div
    style={{
      width: "1px",
      background: "rgba(255,255,255,0.2)",
      marginLeft: "8px",
      height: "18px",
      marginRight: "8px"
    }}
  />
);

export const NavTitle = ({ enableMenu, showBrand, showMenu, navStyle }) => (
  <React.Fragment>
    {enableMenu && (
      <Box mr={"10px"} display="flex" alignItems="center">
        <IconButton
          size={34}
          style={{
            padding: "5px",
            color: "rgba(0,0,0)",
            ...navStyle
          }}
          onClick={showMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </IconButton>
      </Box>
    )}
    <NavbarBrand
      style={navStyle}
      display={showBrand ? "block" : "none"}
      mr={2}
      to="/"
    >
      <img
        style={{ maxWidth: "20%", marginLeft: "1rem", marginTop: "6px" }}
        // src={require("../Branding/images/vts-logo.png")}
        src={require("../Branding/images/vts-watershed.png")}
        alt="vts-logo"
      />
    </NavbarBrand>
  </React.Fragment>
);

// type Props = {
//   auth: boolean,
//   loading?: boolean,
//   toggleMenu: Function
// };

// type State = {
//   showSearch: boolean
// };

class NavContents2 extends React.Component {
  state = {
    showSearch: false
  };

  render() {
    const { auth } = this.props;

    return (
      <Box alignSelf="stretch" display="flex" alignItems="center" width={1}>
        {auth && <PushNotifications />}

        <Box width={1} px={["16px", null, "24px"]}>
          <Media query="(min-width: 52em)">
            {match => {
              let showBrand = true;

              if (!match && this.state.showSearch) {
                showBrand = false;
              }
              if (auth && !match) {
                showBrand = false;
              }

              return (
                <Flex
                  align="flex-end"
                  height="100%"
                  alignSelf="stretch"
                  width={1}
                >
                  <Flex
                    flex="1 1 auto"
                    alignItems="center"
                    justifyContent={["space-between", null, "flex-start"]}
                    alignSelf="stretch"
                    textAlign="start"
                  >
                    <Box display="flex" alignItems="center">
                      <NavTitle
                        enableMenu={this.props.auth}
                        showMenu={this.props.toggleMenu}
                        showBrand={showBrand}
                      />
                      
                    </Box>
                    <Box flex="1" display="flex" justifyContent="flex-end">
                      {this.props.auth && <NavbarLink navbar to="/">Dashboard</NavbarLink>}
                      {match && (
                        <div
                          style={{
                            width: "2px",
                            background: "#575757",
                            marginLeft: "8px",
                            marginRight: "8px"
                          }}
                        />
                      )}
                      {/* {this.props.auth && <NavbarLink navbar to="/public">Watershed-ed.org</NavbarLink>} */}
                      {/* {match && (
                        <div
                          style={{
                            width: "2px",
                            background: "#575757",
                            marginLeft: "8px",
                            marginRight: "8px"
                          }}
                        />
                      )} */}
                    </Box>
                  </Flex>

                  <Box
                    display={[
                      this.state.showSearch ? "none" : "flex",
                      null,
                      "flex"
                    ]}
                    alignSelf="stretch"
                    alignItems="center"
                    flex="0 1 auto"
                    justify="flex-end"
                    mr="1"
                  >
                    {this.props.auth ? (
                      <Query query={ViewerQuery}>
                        {({ data, loading, error, client }) => {
                          if (loading || this.props.loading) {
                            return <NavPreview />;
                          }

                          if (error) {
                            console.error(error);
                            return null;
                          }

                          if (!data.viewer) {
                            console.error("No viewer found.");
                            authController.signout();
                            return null;
                          }

                          return (
                            <Flex alignItems="center" alignSelf="stretch">
                              <UnreadMessages>
                                {notify => (
                                  <Box
                                    position={[null, null, "relative"]}
                                    display="flex"
                                    alignItems="center"
                                    mr={2}
                                  >
                                    <Popover
                                      position={"absolute"}
                                      right={0}
                                      top={["56px", null, "calc(100% + 20px)"]}
                                      width={["100vw", null, "450px"]}
                                      textAlign="left"
                                      content={({ isShown, close }) => (
                                        <React.Fragment>
                                          {isShown && (
                                            <UpdateRead viewer={data.viewer} />
                                          )}
                                          <EventDropdown
                                            close={close}
                                            viewer={data.viewer}
                                          />
                                        </React.Fragment>
                                      )}
                                    >
                                      <div>
                                        <NotificationButton
                                          onToggle={this.toggleEvents}
                                          count={notify.state.count}
                                        />
                                      </div>
                                    </Popover>
                                  </Box>
                                )}
                              </UnreadMessages>
                              <Box position={[null, null, "relative"]}>
                                <UserDropdown viewer={data.viewer} />
                              </Box>
                            </Flex>
                          );
                        }}
                      </Query>
                    ) : (
                      <React.Fragment>
                        <NavLink
                          login="true"
                          to="/login?register=true"
                          style={{
                            color: "#f5821e",
                            border: "1px solid #f5821e",
                            background: "#FFF",
                            padding: "10px 30px",
                            borderRadius: "22px",
                            margin: "0 2rem"
                          }}
                        >
                          Sign up
                        </NavLink>
                        <NavLink
                          login="true"
                          to="/login"
                          style={{
                            background: "#f5821e",
                            padding: "10px 30px",
                            borderRadius: "22px",
                          }}
                        >
                          Login
                        </NavLink>
                        {/* <Button size="small" appearance="primary" to="/login">
                          Login
                        </Button> */}
                      </React.Fragment>
                    )}
                  </Box>
                </Flex>
              );
            }}
          </Media>
        </Box>
      </Box>
    );
  }

  toggleEvents = () => {
    this.setState({ showEvents: true });
  };

  showSearch = () => {
    this.setState({ showSearch: true });
    document.body.classList.add("modal-open");
  };

  hideSearch = () => {
    this.setState({ showSearch: false });
    document.body.classList.remove("modal-open");
  };

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }
}

const UserDropdown = ({ viewer }) => {
  return (
    <Popover
      position={"absolute"}
      right={0}
      top={["56px", null, "calc(100% + 20px)"]}
      width={["100vw", null, "250px"]}
      textAlign="left"
      content={
        <Box py={1} width={1}>
          <Box mx="4px" px={"1.5rem"} py={1}>
            <Box>
              <Box>
                <Text faded>
                  Signed in as <br />
                  <Text color="black" fontWeight={500}>
                    {viewer.name}
                  </Text>
                </Text>
              </Box>
            </Box>
          </Box>
          <Dropdown.Divider />
          <Dropdown.ItemLink to="/settings">Settings</Dropdown.ItemLink>
          {viewer.isAdmin && (
            <Dropdown.ItemLink to="/admin">Admin panel</Dropdown.ItemLink>
          )}
          <Dropdown.Item
            href="#"
            onClick={e => {
              e.preventDefault();
              authController.signout();
            }}
          >
            Sign out
          </Dropdown.Item>
        </Box>
      }
    >
      <div>
        <IconButton
          label={viewer.name}
          style={{
            cursor: "pointer",
            borderRadius: "50%"
          }}
        >
          <Avatar
            hideTooltip
            ariaHidden={false}
            img={viewer.photo}
            name={viewer.name}
            size="small"
          />
        </IconButton>
      </div>
    </Popover>
  );
};

const NavPreview = () => (
  <Flex w="82px" flex="0 0 82px" alignItems="center" alignSelf="stretch">
    <Box flex="0 0 34px" mr={2}>
      <NotificationButton count={0} disabled />
    </Box>
    <Box flex="0 0 34px">
      <LoadingPlaceholder />
    </Box>
  </Flex>
);

const LoadingPlaceholder = () => (
  <ContentLoader
    style={{ width: "34px", height: "34px" }}
    width={36}
    primaryColor="rgba(255,255,255,0.75)"
    secondaryColor="rgba(255,255,255,0.5)"
    height={36}
  >
    <circle cx="17" cy="19" r="16" />
  </ContentLoader>
);

const NotificationButton = ({ count, onToggle, disabled }) => {
  return (
    <IconButton
      onClick={onToggle}
      disabled={disabled}
      label="Notifications"
      style={{
        position: "relative",
        color: "#575757",
        padding: "5px 10px"
      }}
      size={40}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-bell"
      >
        <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0" />
      </svg>
      {count > 0 && (
        <Badge
          bg="green.20"
          color="white"
          style={{
            pointerEvents: "none",
            position: "absolute",
            top: 0,
            right: 0,
            fontSize: "11px"
          }}
          aria-label="You have unread notifications"
        >
          {kFormatter(count)}
        </Badge>
      )}
    </IconButton>
  );
};

function kFormatter(num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
}

export default NavContents2;
