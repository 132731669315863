import gql from "graphql-tag";

export const UsersQuery = gql`
  query Users($query: String) {
    users(query: $query) {
      id
      name
      email
      photo {
        id
        public_id
      }
    }
  }
`;
