import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";

const PrivateRoute = ({ render, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated) {
          if (Component) return <Component {...props} />;
          else if (render) return render(props);
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
