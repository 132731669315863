import React, { PureComponent } from "react";
import styled, { css } from "styled-components/macro";
import { themeGet } from "@styled-system/theme-get";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import Box from "./Box";
import Text from "./Text";

const numberMask = createNumberMask({
  prefix: "",
  suffix: " $"
});

export default class TextInput extends PureComponent {
  static defaultProps = {
    size: "default",
    textarea: false
  };

  componentDidMount() {
    if (this.props.autofocus && this.el) {
      this.el.focus();
    }
  }

  render() {
    const {
      name,
      label,
      placeholder,
      helpText,
      type,
      hideLabel,
      textarea,
      disabled,
      value,
      size,
      error,
      onChange,
      dollar,
      autofocus,
      defaultValue,
      inputStyle,
      ...other
    } = this.props;

    let Component = textarea ? Textarea : Input;
    if (dollar) {
      Component = StyledDollarInput;
    }
    return (
      <Box mb={2} {...other}>
        <Label hideLabel={hideLabel} htmlFor={name}>
          {label}
        </Label>
        <Component
          size={size}
          placeholder={placeholder}
          type={type}
          name={name}
          ref={el => (this.el = el)}
          id={name}
          disabled={disabled}
          autoFocus={autofocus}
          defaultValue={defaultValue}
          style={inputStyle}
          error={error}
          value={value}
          onChange={onChange}
        />
        {helpText && (
          <Box my={1}>
            <Text lineHeight="1.5" fontSize={1} faded>
              {helpText}
            </Text>
          </Box>
        )}
        {typeof error === "string" && <ErrorText>{error}</ErrorText>}
      </Box>
    );
  }
}

export const ErrorText = ({ children }) => (
  <Box my={1}>
    <Text fontSize={1} color="red.20">
      {children}
    </Text>
  </Box>
);

const getPadding = props => {
  if (props.size === "large") return "0.6rem 1rem";
  if (props.size === "small") return ".25rem .5rem";
  return "0.6rem 1rem";
};

const getFontSize = props => {
  if (props.size === "large") return props.theme.fontSizes[2];
  if (props.size === "small") return props.theme.fontSizes[1];
  return props.theme.fontSizes[1];
};

const normalShadow = props => {
  return `inset 0 0 0 1px ${props.theme.colors.grayAlpha[6]}, inset 0 1px 2px ${
    props.theme.colors.grayAlpha[5]
  }`;
};

const focusShadow = props => {
  return `inset 0 0 2px ${props.theme.colors.grayAlpha[7]}, inset 0 0 0 1px ${
    props.theme.colors.blueAlpha[15]
  }, 0 0 0 3px ${props.theme.colors.blueAlpha[4]}`;
};

const disabledShadow = props => {
  return `inset 0 0 0 1px ${props.theme.colors.grayAlpha[5]}`;
};

const inputStyles = css`
  display: block;
  width: 100%;
  padding: ${getPadding};
  font-size: ${getFontSize}px;
  line-height: 1.5;
  color: ${themeGet("colors.black")};
  background-color: white;
  background-image: none;
  box-shadow: ${normalShadow};
  background-clip: padding-box;
  box-sizing: border-box;
  touch-action: manipulation;
  font-family: ${themeGet("sansFont")};
  border: none;
  border-radius: ${themeGet("radii.0")}px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  ::placeholder {
    color: ${themeGet("colors.gray.5")};
  }
  :focus {
    z-index: 2;
    box-shadow: ${focusShadow};
    outline: none;
  }
  :disabled {
    box-shadow: ${disabledShadow};
  }
  ${props => {
    if (props.error) {
      const red = props.theme.colors.red;
      const neutral = props.theme.colors.grayAlpha;

      return css`
        box-shadow: ${`inset 0 0 0 1px ${red[20]}, inset 0 1px 1px ${
          neutral[7]
        }`};
      `;
    }
  }};
`;

const Input = styled.input`
  ${inputStyles};
`;

const Textarea = styled.textarea`
  ${inputStyles};
  overflow: auto;
  resize: vertical;
`;

export const Label = styled.label`
  display: inline-block;
  font-size: ${themeGet("fontSizes.1")}px;
  line-height: 1.5;
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("sansFont")};
  margin-bottom: ${themeGet("space.1")}px;
  ${props => {
    if (props.hideLabel) {
      return css`
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        border: 0;
      `;
    }
  }};
`;

const StyledDollarInput = styled(Input)`
  ${inputStyles}
`;

export const DollarInput = providedProps => (
  <MaskedInput
    mask={numberMask}
    placeholder="Enter a dollar amount"
    {...providedProps}
  />
);
