import React from "react";
import { Modal } from "../../Kit";
import {
    FlexButton,
} from "../index";
import GLOBAL_CONSTANTS from "../../constants/config";


class Popover extends React.Component {
    state = {
        showModal: true
    }
    render() {
        return (
            <Modal
                style={{ textAlign: "center" }}
                narrow
                onRequestClose={() => this.setState({ showModal: false })}
                isOpen={this.state.showModal}
            >
                <Modal.Header
                    style={{ textAlign: "center" }}
                    title="Pilot VTS in the 2023-2024 school year!"
                    onRequestClose={() => this.setState({ showModal: false })}
                />
                <Modal.Body>
                    <div style={{ textAlign: "center", color: "#f5821e", fontSize: "25px", fontWeight: "600" }}>New School Pilot Package</div>
                    <div style={{ padding: "1rem 3rem 2rem 3rem", fontSize: "18px", fontWeight: "400"}}>
                        The best way to understand the power of VTS is to experience it in action with your own students. 
                        The New School Pilot Package makes it easy and affordable for small teams - $500 for 3 teacher enrollments, $1,000 for 6 teacher enrollments 
                        - to adopt and evaluate VTS on behalf of their school.
                    </div>
                    <FlexButton style={{minWidth: "15rem", width: "15rem", margin: "0 auto 10px auto", fontSize: "20px" }}>
                        <a
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "event",
                                    eventProps: {
                                        category: "Home page",
                                        action: "Webinar popup clicked",
                                        label: "Webinar popup",
                                        value: "true",
                                    },
                                });
                            }}
                            style={{ textDecoration: "none" }}
                            href="https://bsgouuxlaee.typeform.com/to/IDzqFaDl?typeform-source=www.watershed-ed.org"
                            target={"_blank"}
                        >
                            Learn More
                        </a>
                    </FlexButton>
                    {/* <FlexButton style={{ maxWidth: "370px", margin: "0 auto", fontSize: "20px" }}>
                        <a
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "event",
                                    eventProps: {
                                        category: "Home page",
                                        action: "Webinar popup clicked",
                                        label: "Webinar popup",
                                        value: "true",
                                    },
                                });
                            }}
                            style={{ textDecoration: "none" }}
                            href={GLOBAL_CONSTANTS.WEBINAR_URL_SEP_8}
                            download
                            target={"_blank"}
                        >
                            RSVP here for September 8th
                        </a>
                    </FlexButton> */}
                </Modal.Body>
            </Modal>
        )
    }
}

export default Popover;